<template>
  <div class="page-container">
    <header-tab activeIndex="1"></header-tab>
    <div class="home-d1">
      <el-carousel
        indicator-position="none"
        :height="`${clientHeight}px`"
      >
        <el-carousel-item
          v-for="(item,index) in headDatas"
          :key="index"
        >
          <div
            class="home-carousel"
            v-lazy:background-image="{src: item.bg}"
          >
            <div align="center">
              <span class="home-carousel-s1">{{item.title}}</span>
              <span class="home-carousel-line"></span>
              <span class="home-carousel-s2">{{item.description}}</span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- <cloud-hospital></cloud-hospital> -->
    <college-pharmacy></college-pharmacy>
    <platform></platform>
    <technical-services></technical-services>
  </div>
</template>

<script>
export default {
  name: "home",
  components: {
    "header-tab": () => import("@/components/headerTab"),
    // "cloud-hospital": () => import("./components/CloudHospital"),
    "college-pharmacy": () => import("./components/CollegePharmacy"),
    platform: () => import("./components/Platform"),
    "technical-services": () => import("./components/TechnicalServices"),
  },
  data() {
    return {
      clientHeight: "",
      headDatas: [
        {
          title: "九医云",
          bg: require("../../assets/images/lunbo1.png"),
          description: "以创新技术 助力传统零售药店升级转型",
        },
        // {
        //   title: "九医云医院",
        //   bg: require("../../assets/images/lunbo3.png"),
        //   description: "一站式互联网+医疗服务平台",
        // },
        {
          title: "九医药学院",
          bg: require("../../assets/images/lunbo2.png"),
          description: "医药流通数字化信息管理解决方案",
        },
      ],
    };
  },
  mounted() {
    // 获取浏览器可视区域高度
    this.clientHeight = `${document.documentElement.clientHeight}`; //document.body.clientWidth;
    //console.log(self.clientHeight);
    window.onresize = function temp() {
      this.clientHeight = `${document.documentElement.clientHeight}`;
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.home-d1 {
  position: relative;
  width: 100%;
  height: 100%;
}
.home-carousel {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
}
</style>